export const dataOutside = [
  {
    question:
      "Assuming a 30% tax rate, walk me through 3 statements with a: \n\n$120 decrease in depreciation",
    answer:
      "Starting on the IS...\nPre-tax income up $120, NI up 84\n\nMoving onto the SCF...\nNI up 84, back out depreciation as non-cash, net change in cash is down 36\n\nFinally, on the BS...\nA: Assets up 84; Cash down 36, PP&E up 120\nL: No Change\nSE: Shareholder's Equity up 84; NI up by 84\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "ba1a487b-5af5-428e-a14b-8d9d2ad1b5cb",
  },
  {
    question:
      "Assuming a 30% tax rate, walk me through 3 statements with a: \n\n$50 increase in stock-based compensation",
    answer:
      "Starting on the IS...\nPre-tax income down by 50, NI down by 35\n\nMoving onto the SCF...\nNI down by 35, add back 50 in CFO since stock-based compensation is non-cash expense, net change in cash is up 15\n\nFinally, on the BS...\nA: Assets up by 15; cash up 15\nL: No change in liabilities\nSE: Shareholder's Equity up by 15; Stock-based compensation up 50 and NI down 35\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "c1e32fc0-d906-476d-8b95-3e32c0c00b0d",
  },
  {
    question:
      "Assuming a 30% tax rate, walk me through 3 statements with a:\n\n$100 interest expense (50% cash interest / 50% PIK interest) and $50 interest income",
    answer:
      "Starting on the IS...\nNet interest expense down 50, pre-tax income down by 50, NI down by 35\n\nMoving onto the SCF...\nNI down by 35, add back 50 from PIK interest in CFO, so cash at bottom is up by 15\n\nFinally, on the BS...\nA: Assets up 15, Cash up 15\nL: Liabilities up 50, Long-term debt up 50\nSE: Equity down 35, Retained earnings down 35\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "311ffab9-46f8-4911-a96f-cb64cddc20f4",
  },
  {
    question:
      "Assuming a 30% tax rate, walk me through 3 statements with a:\n\n$20 decrease in Deferred Revenue",
    answer:
      "Starting on the IS...\nRevenue up by 20; assuming no additional expenses, pre-tax income up by 20, NI up by 14\n\nMoving onto the SCF...\nNI up by 14, subtract 20 from Deferred Revenue decrease under CFO, so cash at bottom is down by 6\n\nFinally, on the BS...\nA: Assets down 6, Cash down 6\nL: Liabilities down 20, Deferred Revenue down 20\nSE: Equity up 14, Retained Earnings up 14\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "7b1a13ea-17b5-4530-a3cf-ecae8b9e4dc9",
  },
  {
    question:
      "MULTI-STEP: Assuming a 20% tax rate, walk me through 3 statements with a:\n\nYear 0: Buy PP&E for $100 using Debt. Walk through the 3 statements.\n\nYear 2: PP&E depreciates over 10-year period using straight-line depreciation. After two years, you sell the PP&E for $120. Assume depreciation has been accounted for on the financial statements. Walk through the 3 statements after the sale of PP&E.",
    answer:
      "YEAR 0\n--\nStarting on the IS...\nNo change\n\nMoving onto the SCF...\nIn CFI, outflow of cash $100 to purchase PP&E.\nIn CFF, inflow of cash $100 because debt was raised.\nSo, net change in cash is $0.\n\nFinally, on the BS...\nA: PP&E up $100\nL: Debt up $100\nSE: No change\n\n...and the Balance Sheet balances\n\nYEAR 2\n--\nStarting on the IS...\nGain on sale recorded positively as 40, pre-tax income up 40, NI up 32\n\nMoving onto the SCF...\nNI up 32, subtract 40 from gain on sale under CFO, add 120 under CFI, so cash at bottom is up 112\n\nFinally, on the BS...\nA: Assets up 32, cash up 112, PP&E down 80\nL: no change in liabilities\nSE: Equity up 32, retained earnings up 32\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "2cd57efb-dfd2-4a66-95a5-a39b9f00574f",
  },
  {
    question:
      "MULTI-STEP: Assuming a 20% tax rate, walk me through 3 statements with a:\n\nYear 0: Buy Land for $90 using cash on hand. Walk through the 3 statements.\n\nYear 2: Straight-line depreciation occurs over a 10-year period. After two years, you sell the land for $90. Walk through the 3 statements after the sale of land.",
    answer:
      "YEAR 0\n--\nStarting on the IS...\nNo change\n\nMoving onto the SCF...\nIn CFI outflow of $90 for purchase of land, so net change in cash is $90\n\nFinally, on the BS...\nA: Land up $90, cash down $90\nL: No change\nSE: No change\n...and the Balance Sheet balances\n\nYEAR 2\n--\nTRICK QUESTION: Land does not depreciate!\n\nStarting on the IS...\nGain on sale recorded as 0, pre-tax income up 0, NI up 0 / unchanged\n\nMoving onto the SCF...\nNI unchanged\nAdd 90 from sale of land under CFI, cash up by 90\n\nFinally, on the BS...\nA: Assets unchanged,, cash up 90, land down 90\nL: no changes under liabilities\nSE: NI unchanged",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "22ad4e02-329d-4a5d-b27b-c01bf0c418ef",
  },
  {
    question:
      "MULTI-STEP: Assuming a 20% tax rate, walk me through 3 statements with a:\n\nYou raise $100 debt with 5% interest and 10% yearly principal repayment. You use that money to purchase $100 of short-term assets that have 10% yearly interest income attached.\n\nPart 1) Right when you raise the debt and purchase short term assets, walk me through the 3 statements.\n\nPart 2) After one year, walk me through the 3 statements.",
    answer:
      "Part 1) \nStarting on the IS...\nNo change\n\nMoving onto the SCF...\nIn CFI, outflow of $100 for short term assets.\nIn CFF, increase of $100 because of debt raised.\nSo, net change in cash is $0.\n\nFinally, on the BS...\nA: Short-term assets up $100.\nL: Debt up $100.\nSE: No change.\n\n...and the Balance Sheet balances\n\nPart 2)\nStarting on the IS...\nInterest income is $10 and interest expense is $5\nPretax income increases by $5, Net Income increases by $4\n\nMoving onto the SCF...\nNet Income up by $4, in CFF repay $10 of debt, so net change in cash is down $6\n\nFinally, on the BS...\nA: cash down $6\nL: debt down $10\nSE: Retained Earnings from Net Income up $4\n\n...and the Balance Sheet balances",
    type: "Accounting",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "8e5cee40-eaff-4f85-b341-8da871a8b68c",
  },
  {
    question:
      "How does ____ affect Enterprise Value?\n\nRaise $200m in Debt, use cash to buy a new piece of equipment.",
    answer:
      "EV up by 200\n\nEnterprise Value = Equity Value + Debt + Preferred Stock + Non controlling interests - Cash and Cash Equivalents\n\n+200m Debt\n-200m Cash from Debt\n+200m Cash from Purchase (subtract a negative amount = add)\n\nSo plugging that into the Enterprise Value equation, Enterprise Value would go up by 200m (since cash is subtracted)",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "b164a851-f3e1-4311-a3ef-cd974cb81d98",
  },
  {
    question:
      "How does ____ affect Enterprise Value?\n\nIssue $200m in Equity for an IPO.",
    answer:
      "Enterprise Value = Equity Value + Debt + Preferred Stock + Non controlling interests - Cash and Cash Equivalents\n\n+200 Equity Value\n-200 Cash (increase in cash is subtracted from EV)\n\nEV unchanged",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "8a7e2223-da5e-437e-83ea-8d89a98743d3",
  },
  {
    question:
      "Two companies are 100% comparable. Why might one trade at a premium (e.g. higher EV/EBITDA multiple)?",
    answer:
      "One company may have higher revenue growth rates or higher EBITDA margins",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "ae766560-a912-4ae9-94ea-0686a0fc37fb",
  },
  {
    question: "Would you rather buy a company with a high or low P/E multiple?",
    answer:
      "Generally, would rather buy low and sell high, so would want to buy one with a low P / E multiple that increases over time. \n\nRemember, P / E signifies how much investors are willing to pay per $1 of earnings.",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "7f8e383c-664f-4e83-ac1e-b948a3524296",
  },
  {
    question:
      "What are some multiples you could use for a company with a negative Net Income?",
    answer:
      "Revenue-based multiples (e.g. EV / Revenue)\n\nIndustry-specific multiples (e.g. EV / Unique Users for internet companies)",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "cc411bae-f843-416d-8c90-50b4c3b60de4",
  },
  {
    question:
      "Company A and Company B have identical EV/EBITDA. Company A has a higher P/E multiple. Why might this be the case?",
    answer:
      'Pay attention to the "ITDA" in EBITDA\n\n- Different capital structures (e.g. one has more debt and thus more interest expense)\n- Different Depreciation / Amortization\n- Different tax rates',
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "bd200eff-a955-4e1c-be63-0766ed012f95",
  },
  {
    question:
      "You have a company with an EV/Revenue of 2x and an EV/EBITDA of 10x. What is the EBITDA margin?",
    answer:
      "20%\n\nEBITDA margin = EBITDA / Revenue.\n\nEBITDA / EV  EV / Revenue = .1  2 = .2",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "80be971c-0d01-4190-811c-51b598c8bab3",
  },
  {
    question:
      "A company has a stock price of $20 a share and a P/E of 20x (so EPS is 1). The company has 10M shares outstanding. How does a 2-for-1 stock split affect EV?",
    answer:
      "Does not affect EV, there are now 20m shares outstanding and EPS is now 0.5",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "7d31b0bb-8799-4b50-9cab-cba4ba65940e",
  },
  {
    question:
      "A company has 10,000 shares at $20 a share. There are 100 call options at an exercise price of $10, 50 restricted stock units (RSUs) and 100 convertible bonds at a price of $10 and par value of $100. What is the diluted equity value?",
    answer:
      "Diluted Equity Value = 11,100 * 20 = 222,000 (see below)\n\nOptions: \n- Company receives $1000, 100 new shares created, company able to buy back 50 shares (50 new shares)\n\nRSU:\n- Add 50 restricted stock units (so far 100 new shares)\n\nConvertible Bonds:\n- Par Value / Price = # of shares per convertible bond → $100/10 = 10 shares per convertible bond * 100 convertible bonds = 1000 new shares\n- 1000 + 100 (from prev. steps) = 1,100 → diluted share count is 11,100\n\n- Diluted Equity Value = 11,100 * 20 = 222,000",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "7e30a554-38fd-4ebc-8d8d-76d59b9ada8a",
  },
  {
    question:
      "How does paying down $100M debt affect a company's enterprise value?",
    answer:
      "In this scenario, we would be spending $100M in cash to pay down $100M in debt. Using the enterprise value formula, the changes in debt and cash would equal each other out. \n\nPaying down debt is also considered a capital structure change, so we should immediately recognize that this does not change the enterprise value of the company.",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "8d31ff59-12fe-4ff8-ad58-1aa3887f0ca8",
  },
  {
    question:
      "If two companies have the same growth prospects, market capitalizations and industries why might you value one using EV/Sales and the other using EV/EBITDA?",
    answer:
      "Ideally under a comparables analysis, one would use the same trading multiple to evaluate two similar companies. However, there are certain exceptions to this rule that make it appropriate to evaluate one company on the basis of top line sales and one on the basis of EBITDA. Some of these exceptions include:\n\n● Despite having similar growth prospects and business models, one of the companies may have had historical financial weakness and have a negative EBITDA value. If this is the case, then an EV/EBITDA multiple would not be relevant \n\n● One company may have went through several one-time occurrences such as restructuring costs or legal expenses, which would distort the company's EBITDA value \n\n● The two companies may utilize different business practices that make their EBITDA values incomparable. For example, one company may use operating leases instead of capital leases or rent its properties as opposed to leasing them",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "3b7159ba-440a-4714-81da-2a2cb3f897ca",
  },
  {
    question:
      "What characteristics of a company would generate a higher valuation multiple?",
    answer:
      "This premium in valuation corresponds to a greater interest in the company's stock, suggesting that the company has a competitive advantage over its peers. The most common characteristics that lead to this presumed competitive advantage / a higher valuation multiple include: \n\n● Higher growth projections than peers \n\n● Market leadership / economic moat \n\n● Access to proprietary information or key patents \n\n● Geographic superiority or access to geographically limited resources \n\n● Key personnel such as talented upper management",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "016bacf5-7481-48a0-84dd-d72a96cad503",
  },
  {
    question:
      "What are limitations of using the Exit Multiple Method when conducting valuation?",
    answer:
      "One of the key limitations of using the Exit Multiple Method is that it is difficult to predict what multiple you will be able to sell the company at in the terminal year. Certain industries such as airlines and automotives are notoriously cyclical and can have wildly different valuation multiples at the trough and peak of the industry. The best way to mitigate this is to sensitize your model to accompany a range of different valuation multiples or concurrently use the Gordon Growth method to provide another data point.",
    type: "Enterprise & Equity Value",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "8ef5789f-952c-42ce-b933-56fd79be1d92",
  },
  {
    question: "Is a company with a 50x P/E overvalued or undervalued? Why?",
    answer:
      "- A P/E multiple alone does not tell us if it is over or undervalued. \n\n- We would need to look at the industry average, the expectations for the company's growth and forward performance, and other qualitative and quantitative considerations. \n\n- Maybe the industry average is 40x and this company seems overvalued relative to its performance, or maybe it is lagging behind and this multiple is \"cheap\"\n\n- You could compare it to the S&P 500 P/E, but still wouldn't tell you much since its value should be judged relative to its peer companies",
    type: "Valuation",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "0ad5111e-51b9-4e42-9998-bfaf6c57c3bf",
  },
  {
    question:
      "If you could only chose two financial statements to evaluate a mature company, which two would you pick?",
    answer:
      "There are three traditional financial statements: the balance sheet, the income statement, and the cash flow statement. Here, you would want the balance sheet and the income statement, as the cash flow statement can be constructed from these other two statements. This assumes that you would be given the balance sheet for both the beginning of the time period and the end of the time period.",
    type: "Valuation",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "d0c8b3a9-ad7f-415a-ad6d-588dda10ff3f",
  },
  {
    question:
      "If you could only choose one financial statement to valuate a mature company, which would you pick?",
    answer:
      "It gets a little bit trickier here, but if you could only choose one financial statement then you would likely choose the cash flow statement. This is because the cash and the cash flow statement is the best determinant of a company's health and its ability to sustain itself. \n\nNet income can be skewed by different accounting practices, while cash is the one balance sheet item that can be certifiably trusted. With the cash flow statement, you can also gauge important details such as whether the company is utilizing the matching principle as well as its ability to service interest expenses.",
    type: "Valuation",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "2a36a431-93c8-426d-a3ed-1f3377ffc834",
  },
  {
    question:
      "What is the difference between intrinsic value and relative value?",
    answer:
      "All valuation methods stem in one way or another from the principles of either intrinsic value or relative value. Financiers often combine both methodologies in arriving at an asset's estimated price.\n\nAll valuation methods stem in one way or another from the principles of either intrinsic value or relative value. Financiers often combine both methodologies in arriving at an asset's estimated.\n\nThe relative value of an asset is found by taking into account the value of similar assets, often gauged by trading multiples and financial metrics. Relative valuation methods include but are not limited to: trading comparables, precedent transaction analysis, M&A premiums analysis.",
    type: "Valuation",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "87ddf567-0b3d-42f3-a598-eb1ab710fd76",
  },
  {
    question:
      "What is a DCF/can you walk me through a DCF in under 60 seconds?",
    answer:
      "\"In a DCF analysis, you value a company with the Present Value of its Free Cash Flows plus the Present Value of its Terminal Value. \n\nYou can divide the process into 6 steps:\n\n1. Project a company's Free Cash Flows over a 5-10 year period.\n\n2. Calculate the company's Discount Rate, usually using WACC (Weighted Average Cost of Capital).\n\n3. Discount and sum up the company's Free Cash Flows.\n\n4. Calculate the company's Terminal Value.\n\n5. Discount the Terminal Value to its Present Value.\n\n6. Add the discounted Free Cash Flows to the discounted Terminal Value.\"",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "b1f72634-63b8-44b0-bddc-90a265892933",
  },
  {
    question: "Why do you typically use 5-10 years for the projection period?",
    answer:
      "- Need to project cash flows until they reach a steady state before calculating TV\n\n- Also, that's about as far as you can reasonably predict for most companies\n\n- Less than 5 years would be too short to be useful\n\n- More than 10 years is too difficult to project for most companies.",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "baba8157-382a-4a89-a1c2-977433eb283f",
  },
  {
    question:
      "How would you change a DCF to value a highly speculative technology company?",
    answer:
      '- May employ a longer projection period (may take longer for company to reach "steady state" of cash flows)\n- May use a much higher Discount Rate\n- You may also adjust management\'s growth or profit expectations',
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "191bada3-e5d8-4ab2-9bcb-7801399b3f5e",
  },
  {
    question: "Tell me 3 places where taxes affect a DCF",
    answer:
      "- Calculating Beta (conversion from unlevered to levered)\n- Calculating FCF (NOPAT)\n- Calculating Cost of Debt (interest is tax deductible)",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "11cf3cec-f9b1-4df1-8b1f-8a064b295e86",
  },
  {
    question:
      "If you are valuing a coal mine company, would you use the Gordon Growth Method or the Multiples Method to calculate the TV? Explain.",
    answer:
      "Would use multiples method, since Gordon Growth assumes cash flows exist into perpetuity and coal is a depleting resource",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "fbba214b-e295-4ccb-9bdc-06073dd7d3c1",
  },
  {
    question:
      "A company buys a factory for $100 in its 4th year. How would the DCF/Enterprise Value change for the company?",
    answer:
      "- Include additional CapEx spending of $100 in Year 4 of the DCF, which would reduce Free Cash Flow for that year by $100.\n\n- The Enterprise Value, in turn, would decrease by the present value of $100 in Year 4.\n\n- You would calculate the difference by dividing $100 by ((1 + Discount Rate)^4). Then you would subtract this amount from the Enterprise Value.",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "ef2d4aad-8118-48dc-b463-7b070fe74668",
  },
  {
    question: "What is WACC, conceptually? How do you calculate it?",
    answer:
      '- The Discount Rate therefore reflects not just the time value of money, but also the return that investors require before they can invest. \n\n- It also represents the "risk" of a company, because higher potential returns correspond to higher risk.\n\n- Can be thought of as the "opportunity cost of capital"\n\n- You\'re determining the "cost" of each part of a company\'s capital structure, and then calculating a weighted average based on how much Equity, Debt, and Preferred Stock it has.\n\n- WACC = Cost of Equity  % Equity + Cost of Debt  % Debt  (1 - Tax Rate) + Cost of Preferred Stock  % Preferred Stock',
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "030e6ff3-aca2-48d7-8241-16045d04f8c9",
  },
  {
    question: "How would you calculate the long-term growth rate for the GGM?",
    answer:
      "- Normally you use the country's long-term GDP growth rate, the rate of inflation, or something similarly conservative.\n\n- For companies in developed countries, a long-term growth rate over 5% would be quite aggressive since most developed economies are growing at much less than 5% per year (2-3% may be more realistic).",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "43967a75-d1ae-4743-b294-456c9302df92",
  },
  {
    question:
      "What are the three ways that lowering tax can affect a DCF valuation?",
    answer:
      "Lowering tax seems like a beneficial change for the business, but that may not translate into an improvement in the DCF valuation. There are three main changes, which can oppose each other, that occur when you alter the tax rate:\n\n1) First, simply flowing through an income statement would reveal that net income increases if tax decreases. This would similarly increase the free cash flow amount and improve the valuation\n\n2) Secondly, the after-tax cost of debt is equal to Debt x (1 - tax rate), so decreasing the tax rate would increase the cost of debt. Conceptually, this happens because the effect of the tax shield decreases. Here, an increase of the cost of debt would increase the WACC and lower the valuation\n\n3) Lastly, the tax rate also affects the beta. Assuming that CAPM is being used, the formula for beta is equal to: Unlevered Beta x (1+(D/E)x(1-t). Mathematically, lowering the tax rate will increase the levered beta, which in turn increases the WACC and lowers the valuation. Conceptually, the decrease in the tax rate reduces the tax shield increasing the effect of interest, making the equity riskier\n\nCumulatively, one would have to flow through all three changes to determine the net change in valuation.",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "72c8cb9a-1ed3-4303-9e0d-81141de4da2f",
  },
  {
    question:
      "What are common alterations that one can make to the basic WACC formula to make it more company-specific?",
    answer:
      "The basic WACC formula is a fairly generic formula that can be improved upon by accounting for nuances in a company's business model or capital structure. A couple of examples for making it more company-specific include:\n\n● If assessing a private company or a company with a small market capitalization, one could add a size premium or a liquidity discount. This can be accomplished simply by multiplying the entire WACC by a %, with typical discounts ranging from 15 - 30% \n\n● On top of the standard vehicles of debt and equity, a company may employ preferred shares in their capital structure. One can account for this in the WACC by adding another component to the equation of cost of preferred shares x preferred shares' composition of total capitalization \n\n● If a company recently IPO'd, there may be no historical beta available. Similarly, if a company has no true comparables, using beta to derive cost of equity may not be appropriate. If possible, using the DDM formula to calculate cost of equity would improve the WACC formula for these companies, provided that it has a predictable dividend policy",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "b29aaba8-55d3-4169-b7b3-0a2b062d30a3",
  },
  {
    question:
      "Ignoring the effect of taxes, how would changing from LIFO to FIFO affect FCF during a period of rising costs?",
    answer:
      'LIFO (Last in First Out) is the inventory accounting method that recognizes the more recently purchased inventory as COGS, i.e. the "last goods in" are recognized as the "first goods out". During a period of rising costs, this suggests that COGS would be composed of the more expensively purchased goods.\n\nFIFO (First in First Out) applies the opposite practice, in which the earliest purchased inventory is treated as COGS. Newer inventory, which in this scenario would be purchased at a comparatively higher price, would remain on the balance sheet. In a period of rising costs, FIFO has a lower COGS but has a higher balance sheet value.\n\nWith regards to free cash flow, we should think through the equation to determine its net change. Firstly, COGS decreases, which increases the gross profit and eventually increases the free cash flow. However, we must remember that the inventory amount has increased because more expensive goods are now on the balance sheet. This increase in inventory is an increase in working capital, which is a use of cash. Ignoring taxes, this would be the exact same amount as the COGS decrease, which nullifies the previous effect of COGS and makes the FCF perfectly neutral.\n\nThe conclusion here is that changes in the accounting policy will not materially affect FCF and should not affect valuation. Changing the accounting policy may manipulate the financial results on the income statement or balance sheet, but hold little relevance to a company\'s true value.',
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "80e47727-d386-4416-ba7f-d438b26b0091",
  },
  {
    question:
      "In a DCF valuation, which of the following 3 actions increases the valuation the most: a $10 decrease in capital expenditures, a $10 decrease in expenses or a $10 increase in revenues?",
    answer:
      "All three of these changes will increase the calculated value in a discounted cash flow model, as they either decreases cash outflows or increase cash inflows. However, some of these changes would not move in isolation and would not necessarily result in an increase in company value of $10. The ranking of attractiveness is as follows:\n\n● First would be a decrease in capital expenditures because it is a direct cash use and there is no tax deduction component. Decreasing capital expenditures by $10 directly improves the value of a company by $10, although it is arguable that it may hurt the ability to generate cash flows in the future \n\n● Second would be a decrease in expenses because is only affected by tax. Flowing through an income statement, you would get a direct increase in value equal to $10 * (1-t) \n\n● Third would be an increase in revenues because any generation of revenues requires associated COGS. In addition to COGS, gross profit would also be subject to tax, making this impact on valuation lower",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "5a536cd7-08df-4838-b6f5-32442721cfb8",
  },
  {
    question: "What is the difference between asset beta and equity beta?",
    answer:
      "These two terms are alternate definitions for traditional definitions for betas; asset beta is simply unlevered beta and equity beta is simply levered beta. \n\nThe unlevered beta is the risk of an asset without the additional risk of any leverage that the asset has, which would represent the company's debt. \n\nAdditional leverage increases the risk level for the equity holder, which is accounted for with the levered beta.",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "09e2b30c-3e74-4fb6-88dd-723cd53ac42c",
  },
  {
    question:
      "How would raising $100M debt in Year 3 affect the DCF valuation of a company?",
    answer:
      "Within the context of a DCF, the amount of debt you have directly impacts the WACC formula. Accordingly, you could change the WACC formula for periods after Year 3 and discount cash flows beyond that at the new WACC. Note that the other component of the DCF valuation is based on unlevered free cash flows, so additional interest from any raised debt will not affect them.",
    type: "DCF",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "c16cfd0b-56d4-4bee-a867-f74a8721c594",
  },
  {
    question:
      "Company A has a P/E of 5x and Company B has a P/E of 8x. If Company A purchases Company B in an all-stock deal, is the deal accretive or dilutive?",
    answer:
      "The deal is dilutive because Company A is purchasing a company with a lower earnings yield in an all-stock deal. A quick shortcut is to just compare the P/E multiples of both companies: if you purchase a company with a lower P/E in an all-stock deal, the transaction will be accretive, if you purchase a company with a higher P/E in an all-stock deal, the transaction will be dilutive.\n\nThis question is asking whether the deal is accretive or dilutive. A deal is accretive if the pro forma EPS (i.e. after the acquisition) of the company is higher than the original company's EPS. The change in EPS is dependent on additional earnings from the acquired company, additional interest from raised debt, the opportunity cost of cash and any newly issued shares. This transaction being an all-stock deal indicates that there will be no new raised debt and no foregone cash.\n\nIn an all-stock deal, companies use their own shares as a form of currency, so we start by determining the cost of stock for Company A. Here, we can invert the P/E of the company to determine what the cost of equity would be. Inverting the P/E arrives at the company's earnings yield and implies how much earnings investors get per dollar of share (e.g. inverting 5x gets 20% and implies that an investor gets $0.20 for every dollar invested). We apply the same methodology and invert the P/E of Company B, arriving at a 12.5% earnings yield.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "c4280d6e-41e7-4ccc-8f98-d872dc1ea425",
  },
  {
    question:
      "Company A has a P/E of 5x and Company B has a P/E of 8x. Company A's cost of debt is 5%, its cost of cash interest is 2% and its tax rate is 50%. If Company A purchases Company B using 50% stock, 25% debt and 25% cash, is the deal accretive or dilutive?",
    answer:
      "Here, we must determine the cost of debt and cash for Company A as well as the cost of stock. We already know that Company A's implied cost of equity is 20% and the earnings yield we receive from Company B is 12.5%. To determine the cost of debt, we simply take the given 5% and apply the tax rate because of the tax shield (5%  (1 - 50%) = 2.5%). We determine the cost of cash using an identical methodology (2%  (1 - 50%) = 1.0%).\n\nNext, we need to multiply each of these costs by their weighting. We multiply 50% stock consideration by the 20% cost of equity (10%), 25% debt consideration by the 2.5% after-tax cost of debt (0.625%) and the 25% cash consideration by the 1% after-tax cost of cash (0.25%). We then add each of these rates up (10% + 0.625% + 0.25%) to get 10.875% blended cost. We compare this to the 12.5% and determine that the deal is now accretive.\n\nThis exercise also shows us that in general, debt and cash are cheaper methods of financing.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "9adf7e0b-9f05-448c-99c8-394a33ec51ef",
  },
  {
    question:
      "Company C has a net income of 200, share price of $6 and has 10 shares outstanding. Company B has a net income of 200, share price of $5 and has 6 shares outstanding. If Company C purchases Company D in an all stock deal at a 20% premium, what is the % change in accretion or dilution?",
    answer:
      "Here, we have to calculate the actual % change in accretion or dilution, so we must flow through the actual calculations to determine the change in pro forma EPS. We must first determine the EPS of Company C by dividing the net income of $200 by the 10 shares outstanding, resulting in an EPS of $20.\n\nNext, we determine the purchase price of Company D. We can accomplish this by calculating the market capitalization (share price of $5 x 6 shares outstanding equaling a $30 market cap) and applying the premium of 20% ($30 market cap. x 120% = $36 purchase price).\n\nBecause this is an all stock deal, we divide the Company D's $36 purchase by Company C's $6 share price. This implies that 6 new shares will be created and given to Company D's shareholders ($36/$6 = 6). We add this number to Company C's existing share count of 10 to get to a pro forma share total of 16 shares.\n\nNext, we get the pro forma earnings by simply adding the net income from both companies: $200 + $200 = $400. We then divide this by the pro forma shares of 16 to arise at an EPS of $25 ($400 / 16). This is higher than the original EPS of $20 so we know that the deal is accretive. To determine the actual % change, we divide $25 by $20 to determine that the deal is 25% accretive.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "556ef98c-4db0-47a9-b47e-f1c7dee75d0e",
  },
  {
    question:
      "Company E has an EBITDA of $200M and Company F has an EBITDA of $100M. Let's say that Company E acquires Company F and realizes $100M in revenue synergies from additional unit sales and $100M from cost synergies. What is the pro forma EBITDA? Assume that the pro forma company has a gross margin of 70%.",
    answer:
      "To determine the pro forma EBITDA, we must add the EBITDA of both companies and also add in the synergies. However, we must note that the revenue synergies of $100M are from additional unit sales and are not a direct addition to EBITDA. These revenue synergies of $100M imply that the pro forma company will be able to drive more unit sales, but they will be subject to COGS. The impact on EBITDA will be $100M in revenue synergies x 70% gross margin, equaling $70M in additional EBITDA.\n\nWe then add the remaining synergies and calculate pro forma EBITDA ($200M Company E EBITDA + $100M Company F EBITDA + $100M cost savings + $70M from revenue synergies) to arrive at $470M EBITDA for the pro forma company.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "6ffcbbdc-230f-4cc6-b7c7-fbeff50cf62d",
  },
  {
    question:
      "Under what circumstances would $100M in revenue synergies be a straight addition to the pro forma company's EBITDA?",
    answer:
      "The synergies would be a full $100M if they were driven by a price increase and not additional unit sales. The logic here is that if you are only increasing the pricing of previous products, you will not generate any additional COGS. \n\nThis could be possible if an acquisition improved your pricing power or competitive positioning in the market. It is also possible that a service or software company may be able to convert 100% of the revenue synergies into EBITDA even with higher unit sales, though less likely",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "dce79d44-9357-4b7b-ad47-ae1d1c47f156",
  },
  {
    question: "How can you calculate break-even synergies in an M&A deal?",
    answer:
      "Break-even synergies can be calculated by reversing the typical accretion/dilution calculation if the purchase price and the terms of the M&A deal are known. To accomplish this, one would create a pro forma income statement and make the ordinary adjustments: consolidating earnings and income statement items, increasing interest expense for any debt, decreasing interest revenue for any lost cash, and increasing the number of shares based off of issued equity. \n\nAfter making these adjustments, one could determine how much EPS was lost and how much synergies are required to offset the amount.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "2c593123-fa75-4d57-9ae3-a25e31ada7d7",
  },
  {
    question:
      "How much debt could a company raise for a merger or acquisition?",
    answer:
      "This question is similar to asking how much debt a standalone company would be able to raise. \n\nYou would most commonly assess a firm's ability to raise debt based on its interest coverage ratio, its leverage ratio and its credit rating. \n\nThe key difference in a merger or acquisition scenario is that you would have to look at the combined company's EBITDA figures for these calculations rather than standalone EPS.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "4fb2886f-3713-440d-8fcb-78e54fb01152",
  },
  {
    question: "What is contribution analysis?",
    answer:
      "Contribution analysis is an evaluation of how much each company in a merger contributes to the pro forma financials. By analyzing the companies' pre-existing EBITDA, revenue, and assets, one can understand the specific percentage that each player is adding to the overall pie. \n\nFor a simple example, if Company A had $600M EBITDA and Company B had $400M EBITDA, then contribution analysis would determine that EBITDA was split 60 - 40. \n\nThis tool can be useful in determining voting rights, tax implications, and related deal terms. It can also impact pricing and influence the stock exchange ratio in a stock deal.",
    type: "Merger Model (M&A)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "5d85ea6f-100b-4dd8-b159-080fef39a07b",
  },
  {
    question: "What is the interest tax shield?",
    answer:
      "The interest tax shield is a phenomenon that makes debt a cheaper vehicle of capital than equity. Interest is tax-deductible, as it appears higher than the tax line on the income statement and is treated as a cash expense. \n\nMechanically, the greater the amount of expenses that a company has, the less tax it has to pay, which provides financial benefits for the company. The interest tax shield is one of the reasons that debt is such an attractive financing vehicle and is also the reason we calculate after-tax cost of debt for the WACC. The PV of a tax shield is Debt Amount x Tax Rate.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "85f8d680-ff8f-4d4c-992f-ed1a1f0b1777",
  },
  {
    question:
      "What is leverage and how do its mechanics amplify returns in an LBO?",
    answer:
      "Leverage and the act of \"levering up a company\" refers to taking on debt or other forms of borrowed capital in order to increase a company's returns. LBO's use leverage to improve returns for the investor, which is possible because of three key functions of debt:\n\n● Taking on debt gives you access to other people's capital that you would otherwise not be able to use. A greater resource pool allows you to purchase a greater quantity of productive assets while reducing the up-front cash investment \n\n● Using the company or asset's cash flows to repay debt principal produces a better return than just keeping the cash. This is partially a result of the tax shield that is applied to interest, which is a function of how governments and regulators treat debt. Similarly, allowing interest to be tax-deductible makes debt a cheaper source of capital than equity \n\n● Typically a business experiences growth in EBITDA so the exit price is higher than the entry price even at the same multiple. Since the sponsor typically pays back a lot of the debt, a much larger portion of the exit price belongs to the sponsor, creating high returns. For example, entering and exit at an EV of $100, a sponsor may only invest $25 in cash, but receive $80 upon exit, simply by paying down debt",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "fb5b2fef-da04-4938-a160-c697e92add8f",
  },
  {
    question:
      "What are the three ways investors can retrieve funds or capital in an LBO?",
    answer:
      "There are three major ways an LBO investor can retrieve cash or funds from an LBO investment:\n\n● Exit: The sale of the investment at the end of the holding period is the largest inflow of capital for the sponsor as all gains are realized at this point \n\n● Dividend Recapitalization: A dividend recapitalization is when the LBO candidate pays out a special dividend to the sponsors, funded through additional debt. This increases leverage of the company and typically increases IRR because of the time value of money \n\n● Dividends. Yes, although this is very similar to a dividend recapitalization, it is important to recognize these two things as distinctly different actions",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "3c96ab9b-3430-4ead-9f8e-0c04812ba57a",
  },
  {
    question: "What is the internal rate of return (IRR)?",
    answer:
      "Academically speaking, the IRR is the rate at which the present value of an asset's cash inflows and outflows is equal to 0. \n\nPractically speaking, IRR is the compound rate you can expect to earn on an investment into an asset. A lower initial investment means that the cash outflow is lower, which would increase the IRR. Higher returns in the future means that the cash inflows are higher, which would also increase the IRR. \n\nIf there is only one initial cash outflow and one final inflow, the IRR is simply the compound annual growth rate on the investment. This can be used to sometimes estimate the IRR of projects.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "f0131025-3c7e-45f7-9c40-f21b34cb84be",
  },
  {
    question:
      "What actions or strategies can you take to improve the IRR in an LBO?",
    answer:
      "IRR (internal rate of return) is one of the most used metrics to determine the success of an LBO. There are several actions that a firm can take to improve the IRR, which include:\n\n● Lowering the initial purchase price of the company, which reduces the cash investment \n\n● Improving the exit multiple, which increases the funds received \n\n● Increasing leverage, which reduces the amount of upfront investment required \n\n● Conducting a dividend recapitalization \n\n● Exiting the investment earlier \n\n● Accelerating the company's growth, which should increase EBITDA and the exit multiple \n\n● Improving margins, which has the same effects as faster growth \n\n● Realizing synergies with other portfolio companies or rolling in new acquisitions",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "3b57533d-a26e-450e-8584-6aab7c2633c6",
  },
  {
    question:
      "What are factors that would make an LBO more difficult to perform on a private company?",
    answer:
      "1) First, the financials of a private company are much more opaque than public companies. Private companies are not legally required to publish audited financials, which can make it much more difficult to gauge the health and attractiveness of a company. These financials would be necessary to determine the potential returns a firm could generate and the target's ability to service debt.\n\n2) Second, the absence of floating shares on public exchanges can make it difficult for a firm to acquire a controlling stake in the company. Private equity firms that conduct LBO's can only do so because they hold a controlling stake in the company and can force the target to assume a greater quantity of debt. Without direct access to these shares, the private equity firm would have to directly solicit shareholders.\n\n3) Finally, obtaining debt can be difficult because of a lack of information, history, and credit rating. Public debt markets are difficult to access for private companies because of the above and also because they simply don't have the scale for a major issuance.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "f1f7ff50-0d7a-4a11-8521-605d5291d987",
  },
  {
    question: "What is the rule of 72? What is the rule of 114?",
    answer:
      "The rule of 72 and 114 are two mental math tricks you can use to determine the IRR of an LBO transaction. Whenever you are asked to derive an actual number for an IRR, you can typically use these rules. \n\nThe rule of 72 stipulates that the time it takes to double an investment is 72 divided by that time period. Although not 100% accurate, it will suffice in an interview setting. For example, the rate you get if an investment doubles in 3 years is 24%; 4 years is 18%; 5 years is 14.4%, etc. \n\nThe rule of 114 stipulates that the time it takes to triple an investment is 114 divided by that time period. For example, the rate you get if an investment triples in 3 years is 38%; 4 years is 28.5%, 5 years is 25%, etc.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "33e1b91e-c1e1-47ad-8081-83801107d407",
  },
  {
    question:
      "If you purchased a company for $100M and sold it for $200M after 5 years, what was your IRR?",
    answer:
      "Whenever asked a question about IRR, you must first clarify how much debt the company had at the beginning of the holding period and how much debt it had at the end of the period. \n\nThe company's debt at the start of the period reduces the amount of initial investment and what the cash outflows are, while the company's debt at the end of the period must be paid down before returns can be realized. \n\nNow armed with the rule of 72, we can recognize that this is simply a doubling of an investment over 5 years. Assuming that the company had no debt throughout the entire investment, the IRR would be approximately 14.4% (actually closer to 15%).",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "26b85708-3149-4693-9bd4-af50ac0cec3b",
  },
  {
    question: "Why would a revolver have the lowest interest rate?",
    answer:
      "A revolver is the cheapest form of debt in an LBO. A revolver acts similarly to a credit card, as it is drawn upon if the company has cash shortfalls and is also the first thing paid down. \n\nRevolver interest rates are the lowest because they are pledged against collateral, which reduces its riskiness. \n\nConcurrently, revolvers have the lowest interest rate because commercial banks and lenders treat it as a sweetener in a deal and compete for a company's business based on how low the rate is.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "293e7396-3d26-4882-9337-e23c3915e9e4",
  },
  {
    question:
      "Why would an LBO not be relevant for the metals and mining industry?",
    answer:
      "An ideal LBO candidate has dependable cash flows, low capital expenditures and is not exposed to commodity risk. \n\nThe metals and mining industry embodies the opposite of this; high initial investments, continual capital expenditures, and direct exposure to unpredictable commodity fluctuations. \n\nDuring periods of weak commodity prices, senior miners are forced to sell their assets with high cash costs, while junior miners do not have enough money to fuel their capital expenditures. \n\nAccordingly, mergers are more common in the metals and mining industry.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "d7f4d4ef-cedf-43f7-8a4e-809a4c3db66d",
  },
  {
    question:
      "If a company could service debt up to 7X EBITDA, why would it only take debt up to 5X EBITDA?",
    answer:
      "Raising the maximum amount of debt is not always in the best interest of a company. Some of the reasons that make additional debt unattractive include, but are not limited to:\n\n● It may be a bad secular debt market and it may be hard to raise capital right now. Interest rates could be comparatively higher than the future, which might make it better to defer raising debt \n\n● Raising more debt would hurt cash flows, which increases the overall company risk and compromises its profitability \n\n● Additional debt may impact the company's perception among potential investors or credit agencies, potentially leading to a reduction to the company's credit rating",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "904af109-3a42-47db-b16f-c490d1f5a755",
  },
  {
    question:
      "You have the option of either receiving $200M now or $40M each year for 5 years. What IRR would you need to make the value of these two options equal?",
    answer:
      "This is a trick question, which is tipped off slightly as it is very difficult to mentally calculate an IRR to gauge two investment options.\n\nHere, we remember that IRR makes all future cash flows equal 0 when discounted to the present value. If any of the five future $40M payments are discounted, the sum of their present value would be less than $200M. If any of the five future $40M payments are appreciated, the sum of their present value would be greater than $200M. The only mathematical possibility is if the IRR is 0.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "31eadd29-9591-464b-b29b-9ddf05aa8d8b",
  },
  {
    question:
      "There are two companies with identical growth prospects, margins, business models, etc. The only difference is that one company has 50% debt-to-total capitalization, while the other has 0%. If you were a PE firm and were going to bring the company's debt-to-total capitalization to 70%, which investment would yield a higher IRR (assuming that the equity purchase price is the same)?",
    answer:
      "First, we make the assumption that the ability to service debt is the same for both companies, so the interest rates, covenants and debt tolerance for both firms is identical.\n\nWith that out of the way, we recognize that the two company's initial debt-to-total capitalization is irrelevant from a returns basis. It does not matter if you are the firm who raised the debt or if the debt was refinanced from before, the IRR and returns will be the same mathematically. At 70% debt-to-total capitalization, the EV of the two companies would be the same, implying that the IRR would be the same as well.\n\nHowever, this assumes that the purchase EV is the same in both situations. This is not entirely correct because sponsors typically have to pay a premium on equity (control premium), which does not apply to debt. In this case, the company with the higher debt will result in a higher IRR because it will have a lower purchase price.",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "39f7d7e3-3daa-47e1-8c8d-fb37140fd15a",
  },
  {
    question:
      "Company XYZ has $50M EBITDA, a 6X EBITDA multiple, $200M in bank debt and $200M in high yield debt. What is the value of the debt? What dollar value is the debt trading at?",
    answer:
      "First, we can determine that the EV of the company is $300M ($50M EBITDA x 6X EBITDA multiple). We can also determine that the total debt of the company is $400M ($200M bank debt + $200M high yield debt). It is clear at this point that the company is undergoing bankruptcy. Based on this, market capitalization and cash are $0 so we allocate the EV to the different tranches of debt by their seniority. We know that bank debt has a higher priority than high yield debt so we allocate $200M from the EV to it.\n\nThe remaining $100M in EV ($300M EV - $200M bank debt) represents the value of the high yield debt. The dollar value is $0.50 because it is trading at half of its full value ($100M / $200M)",
    type: "Leveraged Buyouts (LBOs)",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "9b712743-8839-4c61-9a74-a89463d5652c",
  },
  {
    question:
      "What is the market cap for a company that has...\n\n$2bn in assets\n3x Debt to Equity\n2x P / BV...",
    answer:
      "Answer: 1bn\n\nA = L + SE\n\nIf A = 2bn, we know L + SE = 2bn since A = L + SE. \n\nThus, since Debt is 3x Equity, we know that Liabilities are 1.5bn and Equity is .5bn or 500mm (add up to 2bn).\n\nBV (Book Value) is essentially shareholder's equity, and since the P / BV is 2x, we know that the market cap of the company is 1bn (2 * 500mm).",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "bac1d79c-6b60-4de6-9bbc-dc97be84ac0e",
  },
  {
    question:
      "Company A has a market capitalization of $6B and purchases Company B at a market capitalization of $3B. If Company A owns 75% of the pro forma company, how much cash did it use in the transaction?",
    answer:
      "The first clue is that Company A's shareholders own 75% of the pro forma company. \n\nBecause all of the shares of Company B disappear after the transaction closes, the implied market capitalization of the pro forma company is equal to $8B ($6B / 75%). The pro forma value of the company is equal to $9B (Company A $6B + Company B $3B), so the difference of $1B must be cash. \n\nThis transaction says that Company B shareholders own $2B of stock in the pro forma company and were paid $1B in cash in exchange for their old shares worth $3B.",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "da795da6-f4c7-44b5-a115-c70a8ada9019",
  },
  {
    question:
      "Would you rather us hand you $1,000 today or $100 every year into perpetuity?",
    answer:
      "Depends on the discount rate (r).\n\nPV of Perpetuity = X / r\n\nIf r < 10%, would rather take 100 every year.\n\nIf r > 10%, would rather take $1,000 today.",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "dff3b6c4-96c3-48cb-9aa4-7ae0fa7f29fe",
  },
  {
    question: "What is the Beta of a gambling ring?",
    answer: "0 - no correlation to market",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "c872f99b-8b16-4e20-8792-c17a1b751794",
  },
  {
    question: "2 ways to return capital to shareholders",
    answer:
      "1) Dividends → taxed twice, shareholders have to pay taxes immediately\n\n2) Company buys back shares → signal to shareholders that the company thinks it is undervalued, and shareholders don't need to pay taxes right away (capital gains)",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "aa10ba5e-1b57-4290-93f3-1be9c3bb0b2e",
  },
  {
    question: "What is the P/E of cash?",
    answer:
      "P/E represents price-to-earnings and in principle can be applied to the vehicle of cash. This concept is best understood using a price of $1 and determining how much you could earn off that $1. At the minimum, you would be able to invest it in risk-free assets, which would ordinarily yield between 1-3%. If the price is $1 and the earnings are between $0.01 and $0.03, the P/E could potentially range between 33x to 100x.",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "b190dc5c-0d0c-451c-8420-9a38210dc4f8",
  },
  {
    question: "5 things a company can do with cash?",
    answer:
      "1) Finance future project / CapEx\n2) Pay back debt\n3) Acquisitions\n4) Stock buy-backs\n5) Issue cash dividends",
    type: "Brain Teasers / Misc.",
    subType: "",
    collection: "Outside the Guide",
    output: "output-outside-guide",
    credit: null,
    id: "ecc07e8b-46dd-48da-9dcf-b70a9ca51875",
  },
]
