export const dataPE = [
  {
    question: "Why is EBITDA important?",
    answer:
      "- Proxy for CF\n- Leverage neutral\n- EV multiple\n- Doesn't include many nonrecurring items",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "0c4fe817-231c-4de8-b57f-0d758d027052",
  },
  {
    question: "What does EBITDA lack and not take into account?",
    answer:
      "- Capex\n- Working capital\n\n- Doesn't compare 2 companies apples-to-apples (e.g. two retail companies where one company may rent stores and the other actually owns the stores as high fixed assets. With EBITDA, the one store that rents, has rent expense included, whereas the company that has large depreciation expense due to high PPE that's excluded.)",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "e8264592-65ec-4452-a6b4-038ef3313202",
  },
  {
    question:
      "Two firms of equal EBITDA and IRR. Which one do you invest in? What factors do you look for?",
    answer:
      "If only equal EBITDA, look at capex, working capital, and actual FCF if everything else is exactly the same. \n\nIf they have identical IRRs also, then you need to consider factors such as risk to assumptions and cash flows. What is the growth of the business and how confident are you? What are the industry dynamics and risk factors? What is the sustainability of business model? Are they overly exposed to a large supplier or volatile input such as a commodity? Barriers to entry, is it a fully competitive market?",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "74ad9400-9fa4-43d3-874a-e099fd925bd1",
  },
  {
    question: "Describe the different pieces of capital structure.",
    answer:
      "Revolver, term loan A,B, Mezzanine, High Yield debt, preferred equity, common, options/warrants. Revolver and term loan secured. High Yield usually unsecured",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "5eb09c00-2492-4ecd-926a-dceac368ac58",
  },
  {
    question:
      "Do you think a multiple of EBITDA is always the most appropriate financial metric to buy off of? What about P/E?",
    answer:
      "Well it does not take into account Capital expenditures or investment in working capital. Sometimes EBIT is the better multiple with companies with large rent expense vs. high D&A. If there is negative EBITDA, a revenue multiple may be more appropriate. P/E takes leverage into consideration which is not good if you plan to alter the capital structure. Often includes non recurring and unusual accounting differences.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "8bd13661-fcb6-4321-bb5c-4c74fe15b336",
  },
  {
    question:
      "How do you view maintenance, growth, and acquisition capex when analyzing a company?",
    answer:
      "If a company has high capital expenditures but relatively low maintenance capex, then it could still be a good LBO candidate. Key to determine how much of the capex is discretionary and how much is mandatory",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "19b1597e-4036-4734-bd75-5906ad50bad8",
  },
  {
    question:
      "Describe sources and uses to me. What are 4 main pieces of both?",
    answer:
      "Sources are the source of the capital used to finance a transaction.\n- Excess cash, senior debt, subordinated debt, and sponsor equity \n\nUses are the use of that capital in the transaction\n- Equity purchase price, refinance existing debt, fees",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "ac2754f7-94ac-4f5d-8ee1-df8b8a60ad1b",
  },
  {
    question: "Explain how leverage is beneficial to equity holder",
    answer:
      "Allows for a smaller equity check, debt is cheaper than equity on required returns, and it also provides a tax shield",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "f24d5a55-bf56-4fa3-a178-c73d70603ffb",
  },
  {
    question:
      "How do the following affect IRRs?\n\n1. Greater asset write-up\n2. Using less excess cash\n3. Tax shield",
    answer:
      "1. No effect b.c. write up in assets is not depreciable\n2. Lowers IRR b.c. more sponsor equity or debt in\n3. Improves IRR b.c. it lowers amount of cash outflow",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "bfca0698-2a3c-42e9-8166-05fd089787e3",
  },
  {
    question:
      "Why can a company such as a manufacturing company be more highly levered?",
    answer:
      "Typically the cash flows are more stable, and there are assets to collateralize the debt. You can also lever individual plants on occasion and that does not hurt the debt capacity of the parent co.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "55daf6db-dac0-4cfd-901e-40e0b33b3b8f",
  },
  {
    question: "What is IRR? How do you determine it?",
    answer:
      "It's the return necessary for an investment's NPV to equal zero. Determined by equity injected upfront and then equity portion 3-5 years out. Solve for discount rate necessary for future equity value to equal initial investment",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "709c6a82-2ae9-46f5-990a-83b02e68a03f",
  },
  {
    question: "When should a company issue debt instead of equity?",
    answer:
      "As long as the cash flows from the business can adequately cover the interest payments and as long as the cost of debt is less than the cost of equity. At some point the riskiness of additional debt will push the cost of debt above that of cost of equity.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "02017972-6bab-40bd-a92c-b737002d16b1",
  },
  {
    question:
      "Suppose you've build a model with the following assumptions:\n\n- Revenue in year 2=$100mm \n- EBITDA in year 2=$10mm \n- 50% fixed to variable costs\n\nAssume revenue drops by 10% in year 3, what is the impact on EBITDA?",
    answer:
      "Key here is to remember fixed vs. variable. 50% expenses fixed and the other 50% declines by 10%. So EBITDA is $4.5MM",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "fca0dba5-b740-4b12-8672-4fedcfca1377",
  },
  {
    question: "How do you make money in an LBO?",
    answer:
      "Pay down debt, expand EBITDA through revenue growth and margin improvement, company is perceived to be better all around company within the space and people are willing to pay a higher EBITDA multiple (Multiple Expansion), better management team can help with these areas",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "7e6dba26-7397-4f10-9d28-0a9b03b0f948",
  },
  {
    question:
      "What EBITDA and Gross Margin % do you look for in a good candidate?",
    answer:
      "Depends on the industry. Generally 50% gross margin and 20% EBITDA margin would be good",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "cd6b4af4-a0ad-454e-8625-76838383b97b",
  },
  {
    question: "Walk me through the impact of interest expense?",
    answer:
      "Net income is reduced by after-tax amount of interest. That lowers your CFO which limits the amount of cash you have for capital expenditures, working cap investments, and debt amortization. Lower cash is then reflected in balance sheet. Also RE is hit because of lower net income",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "10e3e39b-4d9a-49f4-8494-5bb73221c2ce",
  },
  {
    question: "What is the difference between a DCF model and an LBO model?",
    answer:
      "Both based on cash flows of the business, but DCF is standalone, whereas LBO is used within an acquisition context. LBO is a floor valuation that enables you to see what levels of premium, debt capacity, margin assumptions you, and exit multiples are necessary to get to a desired IRR.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "d09fa2fa-da89-42eb-88be-bc1d44f4d8d5",
  },
  {
    question: "How do you calculate DSO, inventory turns, and days payable?",
    answer:
      "‒ Receivables/Sales * 365 \n‒ Inventory/COGS * 365 \n‒ Accounts payable/COGS * 365",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "7bb4ec54-241f-4331-abe8-dd980de54cd1",
  },
  {
    question: "What factors influence debt capacity?",
    answer:
      "‒ Amount and stability of cash flows \n‒ Conditions of financing markets \n‒ Availability of assets for security",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "f6f41c4a-33f3-4e79-9e6d-475a58fed15a",
  },
  {
    question:
      "If you are making an investment, what are the first three financial items you want to know?",
    answer:
      "Historical and projected revenues \nHistorical and projected EBITDA \nHistorical and projected CapEx",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "e7b3bf6d-5e21-47ff-9606-698fc84aa041",
  },
  {
    question: "What are the pros and cons of an M&A exit?",
    answer:
      "Pros: accessibility of additional resources, liquidity \n\nCons: loss of control, closures or job losses",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "62f610be-2705-4127-b409-5c0f8672484b",
  },
  {
    question: "What are the pros and cons of an IPO exit?",
    answer:
      "Pros: opportunity for growth, increased visibility, profitability, opportunity for liquidity, attract personnel with stock plans\n\nCons: significant time and money, increased scrutiny, ongoing disclosure, control reduced, market reliance",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "830a9282-3b55-4f7e-a2c7-b23209e4aa15",
  },
  {
    question:
      "What financing multiples are most important? What type of financing multiples are you seeing in the market today?",
    answer:
      "- Total Debt / EBITDA = 5.5x - 7.0x depending on industry and cash flows \n- Total Adj. Debt / EBITDAR (for retailers) = 6.0x - 6.5x \n- EBITDA / Interest Expense = 3.0x and above \n- FCF / Total Debt = 5% and above",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "d93dd6b4-5d46-4b44-a8ab-8ab808c21545",
  },
  {
    question: "How do private equity firms make money?",
    answer:
      "- Mgmt fees\n- Carried interest\n- Co-invest opportunities for execs and employees of buyout firm",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "c4ed140b-df03-4396-b90b-64f7fe2f6b07",
  },
  {
    question: "Why would someone want to invest in PE?",
    answer:
      "- Proven returns as high as any asset class over time \n- Considered some of most sophisticated and successful finance professionals \n- Top quartile funds can generate 15-20% returns per year to their investors \n- PE firms highly incentivized to generate returns for clients and interested aligned \n- Placing capital in hands of those with inside information on private companies",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "79c56dec-2bf0-4638-b639-38cd56a47a68",
  },
  {
    question: "What is the difference between an asset and stock sale?",
    answer:
      "Buyers generally prefer to purchase the assets of the business because \na) they can selectively acquire the assets and corresponding liabilities that it deems necessary for the business, \nb) they will be allowed to obtain a step-up in basis for the assets which will allow increased depreciation over the life of the assets. \n\n- Sellers generally prefer a stock sale of the company because \na) they will transfer all liabilities to a buyer and \nb) their will be only one level of taxation created from a stock sale (when the individual shareholders are taxed on the sale of their stock) rather than two levels of taxation (corporation is taxed on sale of the assets and shareholder is taxed on the distribution of proceeds from sale)",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "410a15ef-678c-41e7-a06f-07323007d6ad",
  },
  {
    question:
      "Assuming that two companies have an enterprise value of 600 and EBITDA of 100, what are reasons why the P/E ratios would be different?",
    answer:
      "Income Statement \n1) D&A - lower D&A will result in a higher net income and a lower P/E multiple \n\n2) Tax Rate - lower tax rate will result in higher net income and a lower P/E multiple \n\nBalance Sheet \n1) Cash - higher cash balance will result in a higher P/E \n\n2) Leverage - if the after tax interest rate is less than the unlevered earnings yield, the result of adding any leverage will be to lower the P/E multiple",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "08fccab8-0604-4906-b839-931a6b82e177",
  },
  {
    question:
      "If you are a CFO and you're about to miss earnings by $0.01, what are some things you can do to change this so you don't miss earnings?",
    answer:
      "1) Switch inventory accounting methods if the cost of goods sold movements are in your favor. If costs are going up and you are currently reporting LIFO, could switch to FIFO so that goods you sell have a lower cost basis. And vice versa if costs are going down.\n\n2) To the extent allowed, switching amortization life lengths could reduce expenses and raise EPS\n\n3) Could buy back shares to reduce total shares outstanding in order to increase EPS",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "bb7d5b35-4829-4e94-b04b-34302f264097",
  },
  {
    question: "What is Sarbanes-Oxley and what are the implications?",
    answer:
      "Sarbanes-Oxley was a bill passed by Congress in 2002 in response to a number of accounting scandals. To reduce the likelihood of accounting scandals, the law established new or enhanced standards for publicly held companies. Those in favor of this law believe it will restore investor confidence by increasing corporate accounting controls. Those opposed to this law believe it will hinder organizations that do not have a surplus of funds to spend on adhering to the new accounting policies",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "6d73e584-b611-434a-90b6-bc97b0af78d9",
  },
  {
    question:
      "You are on the board of directors of a company and own a significant chunk of the company. The CEO, in his annual presentation, states that the company's stock is doing well, as it has gone up 20 percent in the last 12 months. Is the company's stock in fact doing well?",
    answer:
      "- trick question: ask what the Beta is first\n- If Beta is 1, and market has gone up 50%, then company actually has not done well compared to market",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "6d8aeae4-44f8-4599-9afd-4e2bcd05b041",
  },
  {
    question:
      "Of the four debt covenants (minimum EBITDA, maximum capex, minimum interest coverage, maximum leverage), which one is the most important?",
    answer:
      "Minimum EBITDA because EBITDA is the basis of valuation, and if the company can't make its EBITDA covenant, it's a signal that there might be something operationally wrong with the company. A company can sell assets to pay down debt and reduce interest expense, but that will not solve underlying business problems.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "72352a31-ede9-491c-b6ea-086dd5252554",
  },
  {
    question:
      "Given negative news about a company, what happens to the pricing of the equity versus the senior debt?",
    answer:
      "ince equity is riskier and there is more uncertainty associated with it, the equity will be more volatile and decline in price by a greater percentage than the debt.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "0f9d8106-bbd7-42c7-8c77-aef870e8364d",
  },
  {
    question:
      "Given that there is no multiple expansion and flat EBITDA, how can you still generate a return?",
    answer:
      "Reduce interest expense, improve tax rate, depreciation tax shield, the simple act of leverage, pay down debt, pay a dividend, reduce capex, reduce working capital requirements and reduce change in other.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "387dcc68-cc8b-47bc-8cba-f90a08f61609",
  },
  {
    question:
      "What is the different between bank loan and high-yield debt covenants?",
    answer:
      "Bank loans are more strict. For looser covenants, high-yield debt is rewarded with higher interest rates. Covenants can restrict economic activities, finance activities or accounting measurements",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "23d8b01b-2f26-4717-adea-edf7717fd635",
  },
  {
    question:
      "Would you rather have an extra dollar of debt paydown or an extra dollar of EBITDA?",
    answer:
      "EBITDA b.c. of multiplier effect - extra dollar of debt increases equity value by one dollar; extra dollar of EBITDA is multiplied by exit multiple, which results in a great value creation",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "7e5d32c0-7db0-40a6-abb9-72c7abd93057",
  },
  {
    question:
      "You have a company with 3x senior leverage and 5x junior leverage, what happens when you sell a business for 9x EBITDA? What happens when you sell the asset for 8x EBITDA?",
    answer:
      "9x: It's a de-leveraging transaction because pro-forma the company will have a lower total debt to EBITDA ratio.\n\nFor the 8x EBITDA scenario, on a firm basis, it has a neutral impact, but it is de-leveraging on a senior debt basis.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "bc61b5c5-efc2-4b8f-aa3a-619e1757e227",
  },
  {
    question:
      "In an LBO, if cost of debt is 10 percent, what is the minimum return required to break even? Assume a tax rate of 30%.",
    answer:
      "Since interest is tax deductible, the break-even return is the after-tax cost of debt. Assuming tax rate of 30 percent, the break-even return is 7 percent.",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "1e41ab05-4618-40b7-adb1-27a7d8be46c0",
  },
  {
    question: "Explain the concept of duration.",
    answer:
      "Duration = sensitivity of price of bond relative to interest rates (duration of 2 means ~2% price decline in bond price per 1% increase in rates) - first derivative of price/rate function",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "807cde41-ffcb-448a-8f05-3100ca5d026d",
  },
  {
    question:
      'What are some other items that are "debt like" and may be added to EV?',
    answer:
      "Capital leases, some operating leases, unfunded pension obligations, restructuring and environmental liabilities",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "e90fd5ec-de30-4a5e-a3d5-72be51a9b269",
  },
  {
    question:
      'What are some other items that are "cash like" and may be subtracted from EV?',
    answer:
      "NOLs, short/long term investments, equity investments (from 20%-50% ownership of a company) (depending how liquid they are)",
    type: "Private Equity / Buyside Recruiting",
    subType: "Private Equity",
    collection: "Private Equity / Buyside Recruiting",
    output: "output-pe",
    credit: null,
    url: "https://quizlet.com/ie/506235472/outside-the-guide-pe-flash-cards/",
    id: "142bd932-9ff3-4c1f-be38-58e95b175704",
  },
]
