export const dataFIG = [
  {
    question: "How do commercial banks make money?",
    answer:
      "- Gets money (deposits) from customers and earns small amount of interest on it\n- Collects all deposits and loans them in larger quantities and at higher interest rates to organizations that need to borrow money\n- Makes money on the interest rate spread (difference between what they earn on loans and what they issue)\n- Also makes money from non-interest sources of revenue such as credit card fees, asset management fees, investment banking, and securities trading",
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "ebf18df0-e7aa-4fc7-afcc-3af6a36c581b",
  },
  {
    question: "How do insurance firms make money?",
    answer:
      '- Collect PREMIUMS from customers who want to be protected in case of an accident\n- Pay out CLAIMS to customers if / when accident takes place\n\n- Collect premiums upfront and use this money (the "float") to make INVESTMENTS and earn interest / capital gains \n\n- Insurance companies are typically unprofitable or marginally profitable from UNDERWRITING activities and become profitable from INVESTING activities',
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "49d9d88a-ae10-46ad-a2cb-7faf00a09592",
  },
  {
    question:
      "What are 5 key differences between commercial banks and normal companies?",
    answer:
      '1) Balance Sheet First \n- The Balance Sheet drives banks\' performance, and you start the financial statements by projecting the Balance Sheet first.\n\n2) Equity Value Only: \n- You cannot separate a bank\'s operating and financing activities as you can separate those of a traditional company. So, the concept of Enterprise Value does not apply, and you use Equity Value and Equity Value-based multiples instead.\n\n3) Dividend Discount Models (DDM) in Place of DCFs\n- "Free Cash Flow" doesn\'t mean anything for banks because the Change in Working Capital and CapEx do not represent reinvestment in the business. So, you use Dividends as a proxy for FCF, Cost of Equity instead of WACC, and the Dividend Discount Model instead of the Discounted Cash Flow analysis\n\n4) Regulation\n- Banks are highly regulated, and they must maintain minimum amounts of "capital" (Tangible Common Equity with a few modifications) at all times. These requirements constrain their growth.\n\n5) Different Valuation Multiples\n- The Price / Book Value (P / BV), Price / Tangible Book Value (P / TBV), and Price / Earnings (P / E) multiples are all important because these firms are Balance Sheet-driven, and Interest is a huge part of their revenue.',
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "d307a5fe-6867-429f-a96c-f86cbb839ef4",
  },
  {
    question: "How do you value a commercial bank?",
    answer:
      "You use Public Comps, Precedent Transactions, and the Dividend Discount Model in place of the traditional Discounted Cash Flow analysis. Key differences include:\n\n1) Public Comps and Precedent Transactions: \n- Screen based on Total Assets, Loans, or Deposits rather than Revenue or EBITDA; focus on metrics like ROE, ROA, Book Value, and Tangible Book Value; use multiples such as P / E, P / BV, and P / TBV.\n\n2) Dividend Discount Model: \n- Project the bank's future Dividends based on its Regulatory Capital requirements, Total Assets, and Net Income, discount them to Present Value using the Cost of Equity, and add them up. Then, calculate the bank's Terminal Value with a P / BV or P / TBV multiple or the Gordon Growth Method, discount it to Present value, and add it to the Sum of PV of Dividends to determine the bank's Implied Equity Value.\n\n- You cannot separate a bank's operational and financial activities, so you use only Equity Value-based metrics and multiples, and you use Dividends as a proxy for Free Cash Flow since CapEx and the Change in Working Capital do not represent reinvestment for banks.",
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "2accbb66-8e5f-48c9-930c-ec42f775afcc",
  },
  {
    question: "How do you value an insurance firm?",
    answer:
      "- The same way you value a commercial bank: With P / E, P / BV, and P / TBV multiples for Public Comps and Precedent Transactions, and with a Dividend Discount Model (DDM) instead of the traditional DCF analysis.\n\n- You might screen for comparable companies and deals based on Total Assets or Premiums Earned.\n\n- You could also create a Net Asset Value (NAV) model where you mark everything on the firm's Balance Sheet to market value (if it has not already done so), and then you could create a P / NAV multiple by dividing Equity Value by Net Asset Value.\n\n- For a Life Insurance firm, you could also use the Embedded Value methodology, which takes a firm's Net Asset Value (via the method described above) and adds it to the Present Value of future cash profits from the insurance firm's current policies to determine the firm's Implied Equity value.\n\n- Embedded Value represents the Cumulative, After-Tax Cash Flows from Policies in Past Years + the Present Value of Expected After-Tax Cash Flows in Future Years.\n\n- You could then create a P / EV multiple and alternate metrics such as ROEV based on this concept.",
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "9de6727d-2316-4749-a600-f8c0b08e9afe",
  },
  {
    question:
      'What is "Regulatory Capital"? Why do banks and insurance firms need it?',
    answer:
      '- Both banks and insurance firms expect to lose money from customers defaulting or customers getting in accidents.\n\n- They handle expected losses with specific items on their Balance Sheets: The Allowance for Loan Losses for banks and the Claims Reserve for insurance companies.\n\n- But there are also unexpected losses, and Regulatory Capital exists to cover those. It consists mostly of Tangible Common Equity (with adjustments and variations), which serves as a "buffer" against potential, unexpected losses.\n\n- If a bank has to write down a Loan, something must decrease on the L&E side to balance the change. If the bank has enough Regulatory Capital, that "something" will be its Equity rather than customer Deposits (i.e., the money in your checking account).\n\n- Banks must keep their Regulatory Capital / Some Type of Assets above certain percentages, such as 3% or 8%, at all times.\n\n- Banks must also maintain enough Liquid Assets to cover cash outflows and enough Stable Funding to meet their "Required Stable Funding" (Assets multiplied by various adjustment factors).',
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "d686a697-a32b-435c-a921-588f5a36421f",
  },
  {
    question:
      "How are the financial statements different for a commercial bank?",
    answer:
      "• Balance Sheet: \n- Loans on the Assets side and Deposits on the L&E side are the key drivers; there are new items, like the Allowance for Loan Losses (a contra-asset), and more categories for Investments and Securities; items common for normal companies, such as Inventory, may not be present. \n\n• Income Statement: \n- Revenue is divided into Net Interest Income and Non-Interest Income; COGS do not exist; the Provision for Credit Losses is a major new expense; operating expenses are labeled Non-Interest Expenses. \n\n• Cash Flow Statement: \n- The classifications are murkier; all changes in Balance Sheet items are still reflected here, and Net Income still flows in as the first item. New items include the add back for the Provision for Credit Losses and the Changes in Gross Loans and Deposits.",
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "c3992593-fa3e-4712-9c1c-438c06834aa3",
  },
  {
    question:
      "How are the financial statements different for an insurance company?",
    answer:
      "• Balance Sheet: \n- Assets are split into Investment Assets and Non-Investment Assets (Cash, Premiums Receivable, Reinsurance Recoverables, Ceded Unearned Premiums, Deferred Acquisition Costs, etc.). The L&E side has Reserves for Claim Expenses and Unearned Premiums (similar to Deferred Revenue).\n\n• Income Statement: \n- Revenue is divided into Premiums, Net Investment and Interest Income, Gains / (Losses), and Other; COGS do not exist; Claims are the major expense, and other expenses include G&A, Acquisition Costs, and Interest Expense. \n\n• Cash Flow Statement: \n- It's similar, but you must reflect changes in the insurance-specific Balance Sheet line items as well. Also, most insurance companies spend a significant amount on Investments, and that could be considered a recurring item within Investing Activities.",
    type: "Financial Institutions Group (FIG)",
    subType: "FIG",
    collection: "Financial Institutions Group (FIG)",
    output: "output-fig",
    credit: "Breaking into Wall St.",
    id: "595f9dba-8294-4e36-877a-243c14d30136",
  },
]
