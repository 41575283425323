export const dataRX = [
  {
    question:
      "How much do you know about what you actually do in restructuring?",
    answer:
      "Restructuring bankers advised distressed companies - businesses going bankrupt, in the midst of bankruptcy, or getting out of bankruptcy --help them change their capital structure to get out of bankruptcy, avoid it in the first place, or assist with a sale of the company depending on the scenario.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "109478b5-b5a4-497d-b9ea-854837a8a584",
  },
  {
    question:
      'What are the 2 different "sides" of a Restructuring deal? Do you know which one we usually advise?',
    answer:
      "- Bankers can advise either the debtor (the company itself) or the creditors (anyone that has lent the company) money. It's similar to sell-side vs. buy-side M&A - in one you're advising the company trying to sell or get out of the mess it's in, and in the other you're advising buyers and lenders that are trying to take what they can from the company.\n\n- \"Creditors\" are often multiple parties since it's anyone who loaned the company money. There are also \"operational advisors\" that help with the actual turnaround.\n\n- Blackstone and Lazard advise the debtor and Houlihan Lokey advises the creditors (these 3 are commonly as the top groups in the field).",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "c23eea30-e72c-4543-bab3-c3a9c43bfaa8",
  },
  {
    question:
      'Why are you interested in Restructuring besides the fact that it\'s a "hot" area currently?',
    answer:
      "- Gain a very specialized skill set--> become more valuable/employable\n\n- The work is more technical and interesting than M&A\n\n- Broader exposure by seeing bright and not so bright sides of companies",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "8352fc7e-7eec-4a52-b50b-1b169e1c93ff",
  },
  {
    question:
      "How are you going to use your experience in Restructuring for your future career goals?",
    answer:
      "- Gain a very specialized skill set--> become more valuable/employable\n\n- Could assist me down the road if I decide to go back to M&A or normal investing too since I'll have superior technical knowledge to other bankers\n\n- Could eventually work at a Distressed Investments or Special Situations Fund, which most people outside Restructuring don't have access to. (avoid this though since you'll need to say PE isn't for you and you're in IB for more of the long-haul)",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "94c56504-67f8-44cd-bec9-d1897e2dfd5f",
  },
  {
    question:
      "How would a distressed company select its Restructuring bankers?",
    answer:
      "- More so than M&A or IPO processes, Restructuring/Distressed M&A requires extremely specialized knowledge and relationships.\n \n- There are only a few banks with good practices, and they are selected on the basis of their experience during similar deals in the industry as well as their relationships with all the other parties that will be involved in the deal process. \n\n- Lawyers can also be a major source of business, since they're heavily involved with any type of Restructuring/Distressed scenario.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "64a294d8-c1b7-44b6-9796-1adb58bc2da8",
  },
  {
    question: "Why would company go bankrupt in the first place?",
    answer:
      "Here are a few of the more common ones: \n- A company cannot meet its debt obligations/interest payments.\n\n- Creditors can accelerate debt payments and force the company into bankruptcy. \n\n- An acquisition has gone poorly or a company has just written down the value of its assets steeply and needs extra capital to stay afloat (see: investment banking industry). \n\n- There is a liquidity crunch and the company cannot afford to pay its vendors or suppliers.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "4bcbe107-379f-4b17-aa92-08adb7dfdf7e",
  },
  {
    question:
      "What options are available to a distressed company that can't meet debt obligations?",
    answer:
      "1. Refinance and obtain fresh debt/equity.\n2. Sell the company (either as a whole or in pieces in an asset sale)\n3. Restructure its financial obligations to lower interest payments/debt repayments, or issue debt with PIK interest to reduce the cash interest expense\n4. File for bankruptcy and use that opportunity to obtain additional financing, restructure its obligations, and be freed of onerous contracts.\n\n--PIK (Payment-in-kind) is payment of interest w/ additional securities or equity instead of cash. OR the use of a good or service as payment instead of cash",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "062c240e-3d62-4e76-8433-b4d1697341af",
  },
  {
    question:
      "What are the advantages and disadvantages of each option a distressed company has that can't meet debt obligations (refinance, selling the biz, restructuring, and bankruptcy)?",
    answer:
      "1. Refinance\nA: Least disruptive to company and would help revive confidence; \nD: Difficult to attract investors to a company on the verge of going bankrupt\n\n2. Sale\nA: Shareholders could get some value and creditors would be less infuriated, knowing that funds are coming; \nD: Unlikely to obtain a good valuation in a distressed sale, so company might sell for a fraction of its true worth\n\n3. Restructuring\nA: Could resolve problems quickly without 3rd party involvement\nD: Lenders are often reluctant to increase their exposure to the company and management/lenders usually don't see eye-to-eye\n\n4. Bankruptcy\nA: Could be the best way to negotiate with lenders, reduce obligations, and get additional financing\nD: Significant business disruptions and lack of confidence from customers, and equity investors would likely lose all their money",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "7af239e5-d188-4088-9797-0c72c77a44ed",
  },
  {
    question:
      "From the perspective of the creditors, what different strategies do they have available to recover their capital in a distressed situation?",
    answer:
      "These mirror the options that are available to the company itself in a distressed scenario: \n\n1. Lend additional capital/grant equity to company. \n2. Conditional financing - Only agree to invest if the company cuts expenses, stops losing money, and agrees to other terms and covenants. \n3. Sale - Force the company to hire an investment bank to sell itself, or parts of itself. \n4. Foreclosure - Bank seizes collateral and forces a bankruptcy filing.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "6093e211-b536-47cb-a5eb-1b3c51aacc22",
  },
  {
    question:
      "How are Restructuring deals different from other types of transactions?",
    answer:
      '-More complex, involve more parties, require more specialized/technical skills, and have to follow the Bankruptcy legal code\n\n-Unlike most standard M&A deals the negotiation extends beyond two "sides" - it\'s not just the creditors negotiating with the debtors, but also the different creditors negotiating with each other. \n\n-Distressed sales can happen very quickly if the company is on the brink of bankruptcy, but those are different from Bankruptcy scenarios.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "38f25ccd-d3f5-4bc2-8b75-637bd107029e",
  },
  {
    question:
      "What's the difference between Chapter 7 and Chapter 11 bankruptcy?",
    answer:
      '- Ch 7 -"liquidation bankruptcy" - the company is\ntoo far past the point of reorganization and must instead sell off its assets and pay off creditors. A trustee ensures that all this happens according to plan.\n\n- Ch 11 - more of a "reorganization" - the company doesn\'t die, but instead changes the terms on its debt and renegotiates everything to lower interest payments and the dollar value of debt repayments.\n\n-- Ch 7 = heart attack\n-- Ch 11 = rehab',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "1ce56314-015e-4e85-89c4-b215812ae371",
  },
  {
    question:
      "What is debtor-in-possession (DIP) financing and how is it used with distressed companies?",
    answer:
      '- It is money borrowed by the distressed company that has repayment priority over all other existing secured/unsecured debt, equity, and other claims, and is considered "safe" by lenders because it is subject to stricter terms than other forms of financing. \n\n- Theoretically, this makes it easier for distressed companies to emerge from the bankruptcy process - though some argue that DIP financing is actually harmful on an empirical basis. Some DIP lending firms are known for trying to take over companies at a significant discount due to the huge amount of collateral they have. \n\n- One reason companies might choose to file for (Chapter 11) bankruptcy is to get access to DIP financing.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "4f5ef4f7-2a2f-4c6a-8f6a-5d8788566301",
  },
  {
    question:
      "How would you adjust the 3 financial statements for a distressed company when you're doing valuation or modeling work?",
    answer:
      "Here are the most common adjustments:\n• Adjust Cost of Goods Sold for higher vendor costs due to lack of trust from suppliers.\n\n• Add back non-recurring legal / other professional fees associated with the restructuring and/or distressed sale process.\n\n• Add back excess lease expenses (again due to lack of trust) to Operating Income as well as excess salaries (often done so private company owners can save on taxes).\n\n• Working Capital needs to be adjusted for receivables unlikely to turn into cash, overvalued/insufficient inventory, and insufficient payables.\n\n• CapEx spending is often off (if it's too high that might be why they're going bankrupt, if it's too low they might be doing that artificially to save money).",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "17a21f28-60f0-4a83-a812-803375941a7d",
  },
  {
    question:
      "Would those adjustments differ for public companies vs. private companies?",
    answer:
      "Most of the above would apply to public companies as well, but the point about excess salaries does not hold true - it's much tougher for public companies to manipulate the system like that and pay abnormal salaries.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "d198da81-13cf-4b0a-9f8b-eb7b38d69871",
  },
  {
    question:
      "If the market value of a distressed company's debt is greater than the company's assets, what happens to its equity?",
    answer:
      "-Shareholder's Equity goes negative (which is actually not that uncommon and happens all the time in LBOs and when a company is unprofitable).\n\n-Equity Market Cap (which is different - that's just shares outstanding * share price) would remain positive, though, as that can never be negative.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "d7360161-d588-4c23-8ddf-78e1a982ddfa",
  },
  {
    question:
      "In a bankruptcy, what is the order of claims on a company's assets?",
    answer:
      '1. New debtor-in-possession (DIP) lenders (see explanation above)\n2. Secured creditors (revolvers and "bank debt")\n3. Unsecured creditors ("high-yield" bonds)\n4. Subordinated debt investors (similar to high-yield bonds)\n5. Mezzanine investors (convertibles, convertible preferred stock, preferred stock, PIK)\n6. Shareholders (equity investors)\n-"Secured" means that the lender\'s claims are protected by specific assets or collateral; unsecured means anyone who has loaned the company money without collateral.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "7de2e633-5db0-4fae-9ffe-365a9c177819",
  },
  {
    question:
      "How do you measure the cost of debt for a company if it is too distressed to issue additional debt (i.e. investors won't buy any debt from them)?",
    answer:
      "- Have to look at the yields of bonds or the spreads of credit default swaps of comparable companies to get a sense of this. \n\n- Could also just use the current yields on a company's existing debt to estimate this, though it may be difficult if the existing debt is illiquid.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "08825f99-91aa-476e-b397-3e533ad9fa8f",
  },
  {
    question: "How would valuation change for a distressed company?",
    answer:
      "- You use the same methodologies most of the time (public company comparables, precedent transactions, DCF)...\n\n- Except you look more at the lower range of the multiples and make all the accounting adjustments we went through above. \n\n- You also use lower projections for a DCF and anything else that needs projections because you assume a turnaround period is required. \n\n- You might pay more attention to revenue multiples if the company is EBIT/EBITDA/EPS-negative. \n\n- You also look at a liquidation valuation under the assumption that the company's assets will be sold off and used to pay its obligations. \n\n- Sometimes you look at valuations on both an assets-only basis and a current liabilities-assumed basis. This distinction exists because you need to make big adjustments to liabilities with distressed companies.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "ca9493d5-207b-41b2-b364-94d16d6407be",
  },
  {
    question: "How would a DCF analysis be different in a distressed scenario?",
    answer:
      "-Even more of the value would come from the terminal value since you normally assume a few years of cash flow-negative turnaround. \n\n-Might also do a sensitivity table on hitting or missing earnings projections, and also add a premium to WACC (weighted average cost of capital) to make it higher and account for operating distress.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "a80eb73d-af6e-4db1-86ca-680328cf68d0",
  },
  {
    question:
      "Let's say a distressed company approaches you and wants to hire your bank to sell it in a distressed sale - how would the M&A process be different than it would for a healthy company?",
    answer:
      '1. Timing is often quick since the company needs to sell or else they\'ll go bankrupt.\n\n2. Sometimes you\'ll produce fewer "upfront" marketing materials (Information Memoranda, Management Presentations, etc.) in the interest of speed.\n\n3. Creditors often initiate the process rather than the company itself.\n\n4. Unlike normal M&A deals, distressed sales can\'t "fail" - they result in a sale, a bankruptcy or sometimes a restructuring.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "cad63252-0fee-4c85-b016-6c45d67dd98c",
  },
  {
    question:
      "Normally in a sell-side M&A process, you always want to have multiple bidders to increase competition. Is there any reason they'd be especially important in a distressed sale?",
    answer:
      "- Yes - in a distressed sale you have almost no negotiating leverage because you represent\na company that's about to die. \n\n- The only real way to improve price for your client is to have multiple bidders.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "49c9f617-62fd-4e8c-8b9d-b866a5e481a2",
  },
  {
    question:
      "The 2 basic ways you can buy a company are through a stock purchase and an asset purchase. What's the difference, and what would a buyer in a distressed sale prefer? What about the seller?",
    answer:
      "- Stock purchase - you acquire 100% of a company's shares as well as all its assets and\nliabilities (on and off-balance sheet). \n\n- Asset purchase - you acquire only certain assets of a company and assume only certain liabilities (so you can pick and choose exactly what you're getting)\n\n- Companies typically use asset purchases for divestitures, distressed M&A, and smaller private companies; anything large, public, and healthy generally needs to be acquired via a stock purchase.\n\n- A buyer almost always prefers an asset purchase so it can avoid assumption of unknown liabilities (there are also tax advantages for the buyer).\n\n- A (distressed) seller almost always prefers a stock purchase so it can be rid of all its liabilities and because it gets taxed more heavily when selling assets vs. selling the entire business.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "e23b2b75-9a99-42fe-8dcd-69e4d28b09b2",
  },
  {
    question:
      "Sometimes a distressed sale does not end in a conventional stock/asset purchase - what are some other possible outcomes?",
    answer:
      "• Foreclosure (either official or unofficial)\n• General assignment (faster alternative to bankruptcy)\n• Section 363 asset sale (a faster, less risky version of a normal asset sale)\n• Chapter 11 bankruptcy\n• Chapter 7 bankruptcy",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "2a694996-cdad-4dac-b401-b11a4aac9b7b",
  },
  {
    question:
      "Normally M&A processes are kept confidential - is there any reason why a distressed company would want to announce the involvement of a banker in a sale\nprocess?",
    answer:
      "- Generally the company does it if they want\nmore bids / want to increase competition and drive a higher purchase price\n\n- This happens even outside distressed sales",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "30d4f4ca-40dd-48b3-bff7-338f52ee71f7",
  },
  {
    question:
      "Are shareholders likely to receive any compensation in a distressed sale or bankruptcy?",
    answer:
      '- Technically, the answer is "it depends" but \n practically speaking most of the time the answer is "no." \n\n- If a company is truly distressed, the value of its debts and obligations most likely exceed the value of its assets \n\n- So equity investors rarely get much out of a bankruptcy or distressed sale, especially when it ends in liquidation.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "3317b9a2-5a24-4151-9b7b-ad4cf8677165",
  },
  {
    question:
      "Let's say a company wants to sell itself or simply restructure its obligations - why might it be forced into a Chapter 11 bankruptcy?",
    answer:
      "In a lot of cases, aggressive creditors force this to happen - if they won't agree to the restructuring of its obligations or they can't finalize a sale outside court, they might force a company into Chapter 11 by accelerating debt payments.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "9ffc0b3f-8eab-449d-8148-b3e7c9695b3d",
  },
  {
    question:
      'Recently, there has been news of distressed companies like GM "buying back" their debt for 50 cents on the dollar. What\'s the motivation for doing this and how\ndoes it work accounting-wise?',
    answer:
      "- The motivation is simple: use excess balance sheet cash to buy back debt on-the-cheap\nand sharply reduce interest expense and obligations going forward. It works because the\nforegone interest on cash is lower than whatever interest rate they're paying on debt - so they reduce their net interest expense no matter what.\n\n- Many companies are faced with huge debt obligations that have declined significantly in\nvalue but which still have relatively high interest rates, so they're using the opportunity to rid themselves of excess cash and cancel out their existing debt.\n\n- Accounting-wise, it's simple: Balance Sheet cash goes down and debt on the Liabilities &\nEquity side goes down by the same amount to make it balance.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "31c41b7d-bc30-4e32-84f4-7e902e4de4ff",
  },
  {
    question: "What kind of companies would most likely enact debt buy-backs?",
    answer:
      "Most likely over-levered companies - ones with too much debt - that were acquired by PE firms in leveraged buyouts during the boom years, and now face interest payments they have trouble meeting, along with excess cash.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "698a4d0f-f5f5-4038-b622-a88f610daa06",
  },
  {
    question:
      "Why might a creditor might have to take a loss on the debt it loaned to a distressed company?",
    answer:
      "- This happens to lower-priority creditors all the time. \n\n- Remember, secured creditors always come first and get first claim to all the proceeds from a sale or series of asset sales; \n\n- If a creditor is lower on the totem pole, they only get what's left of the proceeds so they have to take a loss on their loans/obligations.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "3f822f60-6bca-4415-8e50-25f8ea8e3208",
  },
  {
    question: "What is the end goal of a given financial restructuring?",
    answer:
      "- A restructuring does not change the amount of debt outstanding in and of itself \n\n- Instead, it changes the terms of the debt, such as interest payments, monthly/quarterly principal repayment requirements, and the covenants.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "55f62891-010f-455a-ab2b-02c4d55fcd93",
  },
  {
    question:
      "What's the difference between a Distressed M&A deal and a Restructuring deal?",
    answer:
      '- "Restructuring" is one possible outcome of a Distressed M&A deal. \n\n- A company can be "distressed" for many reasons, but the solution is not always to restructure its debt obligations - it might declare bankruptcy, it might liquidate and sell off its assets, or it might sell 100% of itself to another company.\n\n- "Restructuring" just refers to what happens when the distressed company in question decides it wants to change around its debt obligations so that it can better repay them in the future.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "48e1e9c5-fdf0-4904-aba6-aacade116c0c",
  },
  {
    question:
      'What\'s the difference between acquiring just the assets of a company and acquiring it on a "current liabilities assumed" basis?',
    answer:
      "- When you acquire the assets of a distressed company, you get literally just the assets.\n\n- But when you acquire the current liabilities as well, you need to make adjustments to account for the fact that a distressed company's working capital can be extremely skewed.\n\n- Specifically, \"owed expense\" line items like Accounts Payable and Accrued Expenses are often much higher than they would be for a healthy company, so you need to subtract the difference if you're assuming the current liabilities.\n\n- This results in a deduction to your valuation - so in most cases the valuation is lower if you're assuming current liabilities.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "4b7c82c0-481d-4ec2-9c7e-22ff475a3167",
  },
  {
    question:
      "How could a decline in a company's share price cause it to go bankrupt?",
    answer:
      "-T rick question. Remember, MARKET CAP DOES NOT EQUAL SHAREHOLDERS' EQUITY. You might be tempted to say something like, \"Shareholders' equity falls!\" but the share price of the company does not affect shareholders' equity, which is a book value.\n\n- What actually happens: as a result of the share price drop, customers, vendors, suppliers, and lenders would be more reluctant to do business with the distressed company - so its revenue might fall and its Accounts Payable and Accrued Expenses line items might climb to unhealthy levels.\n\n- All of that might cause the company to fail or require more capital, but the share price decline itself does not lead to bankruptcy.\n\n- In the case of Bear Stearns in 2008, overnight lenders lost confidence as a result of the sudden share price declines and it completely ran out of liquidity as a result... which is a big problem when your entire business depends on overnight lending.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "d38557e7-cb74-4c1a-ae39-cee5a942c688",
  },
  {
    question:
      "What happens to Accounts Payable Days with a distressed company?",
    answer:
      'They rise and the average AP Days might go well beyond what\'s "normal" for the industry - this is because a distressed company has trouble paying its vendors and suppliers.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "d5a38c9c-d2c3-4364-b977-b25b6ccfbe9b",
  },
  {
    question:
      "Let's say a distressed company wants to raise debt or equity to fix its financial problems rather than selling or declaring bankruptcy. Why might it not be able to do\nthis?",
    answer:
      '• Debt: Sometimes if the company is too small or if investors don\'t believe it has a credible turnaround plan, they will simply refuse to lend it any sort of capital.\n\n• Equity: Same as above, but worse - since equity investors have lower priority than debt investors. Plus, for a distressed company getting "enough" equity can mean selling 100% or near 100% of the company due to its depressed market cap.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "5b77b488-dca5-4b8e-926a-62cf904a4370",
  },
  {
    question:
      "Will the adjusted EBITDA of a distressed company be higher or lower than the value you would get from its financial statements?",
    answer:
      "In most cases it will be higher because you're adjusting for higher-than-normal salaries, one-time legal and restructuring charges, and more.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "e7f77885-ae7a-459d-a6d1-4b8959e067b1",
  },
  {
    question:
      "Would you use Levered Cash Flow for a distressed company in a DCF since it might be encumbered with debt?",
    answer:
      "No. In fact, with distressed companies it's really important to analyze cash flows on a debt-free basis precisely because they might have higher-than-normal debt expenses.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "4e002071-de69-489e-8285-493ba5ca3fbf",
  },
  {
    question:
      "Let's say we're doing a Liquidation Valuation for a distressed company. Why can't we just use the Shareholders' Equity number for its value? Isn't that equal to Assets minus Liabilities?",
    answer:
      "- In a Liquidation Valuation you need to adjust the values of the assets to reflect how much you could get if you sold them off separately. \n\n- You might assume, for example, that you can only recover 50% of the book value of a company's inventory if you tried to sell it off separately.\n\n- Shareholders' Equity is equal to Assets minus Liabilities, but in a Liquidation Valuation we change the values of all the Assets so we can't just use the Shareholders' Equity number.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "bf9f57af-18a9-414a-9142-fcb701ed113a",
  },
  {
    question:
      "What kind of recovery can you expect for different assets in a Liquidation Valuation?",
    answer:
      "This varies A LOT by industry, company and the specific assets, but some rough guidelines: \n\n- Cash: Probably close to 100% because it's the most liquid asset. \n\n- Investments: Varies a lot by what they are and how liquid they are - you might get close to 100% for the ones closet to cash, but significantly less than that for equity investments in other companies. \n\n- Accounts Receivable: Less than what you'd get for cash, because many customers might just not \"pay\" a distressed company. \n\n- Inventory: Less than Cash or AR because inventory is of little use to a different company. \n\n- PP&E: SImilar to cash for land and buildings, and less than that for equipment. \n\n- Intangible Assets: 0%. No one will pay you anything for Goodwill or the value of a brand name - or if they will, it's near-impossible to quantify.",
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "c841f6fc-76f9-4148-b8d6-425d2e87567c",
  },
  {
    question: "How would an LBO model for a distressed company be different?",
    answer:
      '- The purpose of an LBO model here is not to determine the private equity firm\'s IRR, but rather to figure out how quickly the company can pay off its debt obligations as well as what kind of IRR any new debt/equity investors can expect. \n\n- Other than that, it\'s not much different from the "standard" LBO model - the mechanics are the same, but you have different kinds of debt (e.g. Debtor-in-Possession), possibly more tranches, and the returns will probably be lower because it\'s a distressed company, though occasionally "bargain" deals can turn out to be very profitable. \n\n- One structural difference is that a distressed company LBO is more likely to take the form of an asset purchase rather than a stock purchase.',
    type: "Restructuring (Rx) / Distressed M&A",
    subType: "Rx",
    collection: "Restructuring (Rx) / Distressed M&A",
    output: "output-rx",
    credit: "Breaking into Wall St.",
    id: "3b7a9ff0-d9f7-40ad-94c7-43f7f7eedf86",
  },
]
